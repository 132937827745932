input:focus {
  outline-color: var(--purple);
}
select:focus {
  outline-color: var(--purple);
}

body {
  font-family: 'Lato', sans-serif;
}
.custom-dropdown-container {
  display: inline-block;
}
.custom-dropdown-container.open {
  padding: 0 !important;
}

.custom-dropdown {
  display: flex;
  border-radius: 4px;
  cursor: pointer;
  padding: 5px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M13 5.5L8 10.5L3 5.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 10px center;
}
.custom-checkbox {
  margin-left: 62px;
}

.custom-dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 0 0 4px 4px;
  display: none;
  position: absolute;
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(60px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.custom-dropdown-list.show {
  display: block;
  max-height: 50%;
  width: 570px;
  overflow-y: auto;
  scrollbar-width: thin;
}
.custom-dropdown-industry-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 0 0 4px 4px;
  display: none;
  position: absolute;
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(60px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.custom-dropdown-industry-list.show {
  display: block;
  max-height: 500px;
  width: 570px;
  overflow-y: auto;
  scrollbar-width: thin;
}
.custom-dropdown-ownership-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 0 0 4px 4px;
  display: none;
  position: absolute;
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(60px);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
}
.custom-dropdown-ownership-list.show {
  display: block;
  max-height: 300px;
  width: 570px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.custom-dropdown-list::-webkit-scrollbar {
  width: 0;
}

.custom-dropdown-list::-webkit-scrollbar-corner {
  display: none;
}
.custom-dropdown-industry-list::-webkit-scrollbar {
  width: 0;
}
.custom-dropdown-ownership-list::-webkit-scrollbar {
  width: 0;
}

.custom-dropdown-industry-list::-webkit-scrollbar-corner {
  display: none;
}

.custom-dropdown-item {
  display: flex;
  color: #000;
  font-size: 16px;
  line-height: normal;
  padding: 10px 20px 10px 26px;
  &:hover {
    background-color: var(--light-grey)
  }
}
.custom-dropdown-item.disabled {
  color: #aaa;
  cursor: not-allowed;
  pointer-events: none;
}

.buyer-signup-main-container {
  display: flex;
  width: 1440px;
  padding: 35px 0 28px 0;
  justify-content: center;
  align-items: center;
}

.buyer-signup-form-container {
  background-color: #fff;
  border-radius: 10px;
  width: 658px;
  height: 767px;
}
.buyer-container-title {
  color: #1d2739;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  margin-bottom: 16px;
}
.buyer-container-subtitle {
  color: #7e7e7e;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 144.523%;
  margin-bottom: 63px;
}
.input-title {
  display: flex;
  color: var(--white);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.single-input {
  display: flex;
  height: 74px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  flex-shrink: 0;
  margin: 0 43px 40px 43px;
  outline: none;
}

.single-input:not(:last-child) {
  margin: 0 43px 40px 43px;
}

.single-input-edit{
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  flex-shrink: 0;
  margin: 20px 0;
  outline: none;
}


.single-checkbox-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  flex-shrink: 0;
  margin: 0 50px 2px 51px;
  font-size: 14px;
  color: var(--white)
}

.checkbox-container {
  display: flex;
  align-items: center;
}

.checkbox-container:not(:last-child) {
  margin-right: 141px;
}

.custom-svg {
  width: 16px;
  height: 16px;
}

.error-statement {
  display: flex;
  margin-bottom: 20px;
}
.custom-checkbox {
  display: flex;
}
.email-input {
  display: flex;
  height: 74px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  flex-shrink: 0;
  margin: 48px 43px 43px 43px;
}

.custom-input {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  background: var(--light-grey);
  color: var(--white);
  height: 48px;
  outline: none;
}
.custom-input::placeholder{
  color: var(--white);
  font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
}

.custom-input-permission{
  width: 20%;
  padding: 10px;
  border-radius: 0 10px 10px 0;
  border: none;
  background: var(--light-grey);
  color: var(--white);
  height: 48px;
  outline: none;
  
}

.input-permission{
  padding: 10px;
  border-radius: 0 10px 10px 0;
  border: none;
  background: #fff;
  color: #7b7b7b;
  height: 48px;
  outline: none;
}
.custom-input option {
  color: #4a4a4a;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--white);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
}

.password-input-wrapper {
  position: relative;
  width: 100%;
}

.show-password-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
  color: var(--white);
  font-size: 16px;
}
.password-input {
  display: flex;
  height: 74px;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  flex-shrink: 0;
  margin: 0 43px 16px 43px;
}

.send-invite-btn{
display: flex;
justify-content: flex-end;
height: 38px;
padding: 10px;
justify-content: center;
align-items: center;
border-radius: 5px;
background: var(--purple);
color: #FFFFFF;
border: none;
}
input[type="checkbox"] {
  accent-color: var(--purple);
}
.form-check-label {
  color: var(--white);
  margin-left: 5px;
  font-size: 16px;
}
.otp-container {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-bottom: 20px;
}
.otp-input {
  width: 48px;
  height: 48px;
  text-align: center;
  border-radius: 8px;
  color: #fff;
  background: rgba(0, 0, 0, 0.20);
  border: none;
  outline: none;
}
/* .otp-input:focus {
  border-radius: 8px 8px 0px 0px;
  border-bottom: 3px solid #782D8F;
  background: #E5E5E5;
  outline: none;
} */

.proceed-button {
  display: flex;
  width: 382px;
  height: 64px;
  max-height: 64px;
  padding: 22px 38px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  background: #f0f0f0;
  color: #0b0b0b;
  cursor: pointer;
  border-radius: 8px;
  border: none;
  margin-top: 30px;
}

.signup-email-container {
background: rgba(255, 255, 255, 0.30);
  border-radius: 10px;
  width: 658px;
  height: 767px;
  padding: 278.5px 138px 291px 138px;
}
.sign-in-checkbox {
  display: flex;
  justify-content: space-between;
  padding: 0 43px;
  color: #fff;
}

.text-green {
  color: var(--purple);
}

.text-red {
  color: #ff1616;
}
.initial-color {
  color: var(--white);
}
.custom-input.error {
  border: 1px solid #ff1616;
}
.error-message {
  color: #ff1616;
  font-size: 14px;
  margin-top: 4px;
}
.question-mark-icon {
  margin-left: 7px; 
}
.info-message{
display: flex;
width: 146.087px;
height: 48px;
padding: 9.391px 11.478px;
justify-content: center;
align-items: center;
color: #FFF;
font-size: 8px;
font-style: normal;
font-weight: 400;
line-height: normal;
border-radius: 11.478px;
background: rgba(33, 33, 33, 0.68);
backdrop-filter: blur(2px);
z-index: 1 !important;
position: absolute;

}

@media screen and (max-width: 768px) {
  .buyer-signup-main-container,
  .buyer-signup-form-container,
  .buyer-container-subtitle {
    width: 100vw;
    height: 100vh;
    margin: 0 !important;
    padding: 20px 0 20px 20px;
  }
  .single-input,
  .password-input,
  .email-input {
    margin: 0 0 60px 0 !important;
  }
  .single-checkbox-input {
    margin: 0 10px 0 0;
  }
  .custom-input {
    padding: 20px;
    height: auto;
  }
  .custom-checkbox {
    margin-left: 0;
  }

  .basic-info-container {
    overflow-y: scroll;
  }

  .proceed-button {
    width: 100%;
  }

  .custom-dropdown-list.show {
    max-height: 50%;
  }
  .custom-dropdown-industry-list.show {
    max-height: 40%;
  }

  .custom-dropdown-item {
    padding: 10px 16px;
  }

  .checkbox-container:not(:last-child) {
    margin-right: 0;
  }

  .error-statement .single-checkbox-input {
    padding: 5px;
  }
  .sign-in-checkbox{
    padding: 0;
  }
}
