/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}


:root {
  /* Color Pallete */
  --teal: rgb(57, 126, 127);
  --lt-teal: rgb(74, 160, 161);
  --lt-grey: rgb(243, 241, 242);
  --lt-blue: rgb(166,208,208);
  --tan: rgb(196, 154, 110);
  /* --purple: #782D8F; */
  /* --white: rgb(255, 255, 255); */
  --black: rgb(0, 0, 0);
  /* --dark-grey: rgba(0, 0, 0, 0.30); */
  --grey-btn: rgb(151, 151, 151);
  --blue: #A6D0D0;
  --h1: 53px;
  --h2: 33px;
  --h3: 21px;
  --h4: 17px;
  --p: 13px;

--Gradient-vert: linear-gradient(270deg, #CC9866 0%, #722592 102.7%);
--Gradient-hor: linear-gradient(180deg, #CC9866 9.29%, #792E8E 98.58%);
--purple: #782D8F;
--dark-grey: rgba(0, 0, 0, 0.30);
--white: rgb(255, 255, 255);
--light-grey: rgba(7, 7, 7, 0.15);

  /* Site Styles */
  --background: var(--white);
}

body {
  margin: 0;
  /* min-height: 100%; */
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  overflow-x: hidden;
  scroll-behavior: smooth;
}

/* Set core root defaults */
html:focus-within,
html {
  scroll-behavior: smooth;
}

a {
  cursor: pointer;
}


/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

/* Inherit fonts for inputs and buttons */
input,
textarea,
select {
  font: inherit;
}
button{
  font: Lato;
}
/* CSS Reset */

body,
h1, h2, h3, h4, h5, h6,
p,
blockquote,
figure,
ol, ul,
dl,
dd,
form,
fieldset,
legend,
input,
textarea,
pre,
code,
abbr, var {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

.teal-button {
  display: inline-block;
  cursor: pointer;
  transition: background 0.5s cubic-bezier(0.85, 0, 0.15, 1) 0s, color 0.5s cubic-bezier(0.85, 0, 0.15, 1) 0s;
  background: linear-gradient(to top, var(--tan) 50%, var(--teal) 50%);
  background-size: 100% 200%;
  color: white;
}

.container:hover .teal-button {
  background-position: 0 100%;
  color: transparent;
  color: white;
  transform: scale(1.01);
}

.white-button {
  display: inline-block;
  cursor: pointer;
  transition: background 0.5s cubic-bezier(0.85, 0, 0.15, 1) 0s, color 0.5s cubic-bezier(0.85, 0, 0.15, 1) 0s;
  background: linear-gradient(to top, var(--tan) 50%, var(--white) 50%);
  background-size: 100% 200%;
  color: white;
}

.container:hover .white-button {
  background-position: 0 101%;
  color: transparent;
  color: var(--teal);
  transform: scale(1.01);
}

