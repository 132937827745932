
ol,
ul {
  list-style: none;
}
body {
  font-family: 'Lato', sans-serif;
}

/* .signup-main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  z-index: 1000;
} */

.signup-form-container {
  background-color:rgba(255, 255, 255, 0.30);
  border-radius: 10px;
  width: 658px;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  z-index: 1000;
}
.signup-form-container::-webkit-scrollbar {
  width: 0;
}

.title {
  color: var(--white);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  margin-bottom: 16px;
}
.subtitle {
  color: var(--white);
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 144.523%;
  margin-bottom: 83px;
}
.custom-button {
  width: 432px;
  height: 64px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: none;
  background: var(--purple);
  color: #ffffff;
  cursor: pointer;
  border-radius: 8px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  /* margin-bottom: 20px; */
}
.custom-continue-button {
  display: flex;
  width: 432px;
  height: 64px;
  justify-content: center;
  align-items: center;
  gap: 5px;
  border: none;
  background: var(--dark-grey);
  color: #ffffff;
  cursor: pointer;
  border-radius: 8px;
  /* margin-top: 10px; */
  /* position: absolute; */
  bottom: 16px;
  left: 50%;
  /* transform: translateX(-50%); */
  /* margin-bottom: 20px; */
}
.custom-linkedin-button{
  display: flex;
  width: 432px;
  height: 64px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: none;
  background: #F0F0F0;
  color: #585858;
  cursor: pointer;
  border-radius: 8px;
}
.cube-img{
  display: inline;
  margin-top: 70px;
  margin-bottom:24px;
}




@media only screen and (max-width: 768px) {
  .signup-form-container {
    width: 100vw; 
    /* height: 100vh; */
    max-width: none; 
    overflow-y: scroll;
    padding: 20px;
  }
  .signup-main-container{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .custom-button {
    width: 90%; 
    padding: 20px;
  }
  .cube-img{
    margin-top: 24px;
    margin-bottom:12px;
  }
  .custom-continue-button {
    width: 100%;
  }
  .custom-linkedin-button{
    width: 100%;
  }
  
}