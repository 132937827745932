
.pdf-import-container {
  /* position: relative; */
  display: flex;
  /* margin-right: 420px; */

}

.popup-import-container {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 50%;
  top:0;
  transform: translateX(-50%);
  z-index: 999;
}
.pdf-import-btn{
  display: inline-flex;
  height: 42px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  border-radius: 8px;
  border: 1px solid #C9C9C9;
  background: #fff;
  color:  #1D2739;
}

.popup-import-content {
  display: flex;
  flex-direction: column;
  padding: 0 51px 34px 51px;
  width: 618px;
  height: 780px;
  border-radius: 8px;
  background: #FFF;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 60px;
  overflow: scroll;

}
.popup-import-content::-webkit-scrollbar {
  width: 0;
}


.pdf-import-header {
  text-align: center;
  margin-bottom: 20px;
}
.cert-tab{
  display: flex;
}
.cert-tabs{
  display: flex;
  justify-content: space-between;
  padding: 0 55px 0 55px;

}
.tab{
  display: flex;
}

.diverse-group-selection {
  margin-top: 175px;
}

.checkboxes {
  display: flex;
  flex-wrap: wrap;
}

.checkboxes div {
  margin-right: 20px;
}

.bottom-buttons {
  display: flex;
  justify-content: flex-end;
  margin-top: 79px;
  gap: 7.595px;
}

.bottom-buttons button {
  margin-left: 10px;
  padding: 10px;
  cursor: pointer;
}
.drag-drop-container{
  margin-top: 48px;
  width: 516px;
height: 194px;
padding: 8px 8px 8px 19px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
color: #7B7B7B;
}
.checkboxes{
  gap:29px;
}

.cancel-button{
  display: flex;
width: 76.897px;
height: 31.328px;
padding: 5.696px 11.392px;
justify-content: center;
align-items: center;
border-radius: 3.797px;
border: 0.949px solid #7B7B7B;
opacity: 0.9;
color: #7B7B7B;
outline: none;
}
.done-button{
display: flex;
width: 76.897px;
height: 31.328px;
padding: 5.696px 11.392px;
justify-content: center;
align-items: center;
border-radius: 3.797px;
background: #7B7B7B;
color: #FFF;
outline: none;
border: 1px solid #7b7b7b;

}

