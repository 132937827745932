.text-content-terms {
  /* position: relative; */
  /* z-index: 1; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.pricing-table{
  gap:150px;
}
.terms-container {
  /* position: relative; */
  width: 100%;
  height: 598px;
  flex-shrink: 0;
  background: linear-gradient(0deg, #cc9866 -31.55%, #651498 71.76%);
  display: flex;
  justify-content: center;
  align-items: center;
}
.company-container {
  width: 100%;
  height: 765px;
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.terms-of-use {
  color: #fff;
  text-align: center;
  font-family: "Hanken Grotesk";
  font-size: 88px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
.background-image-terms {
  position: absolute;
  top: 200px;
  left: 0;
  width: 100%;
  z-index: -1;
  opacity: 0.1;
}
.company-background-image {
  position: absolute;
  top: 200px;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
}
.background-image-pricing {
  position: relative;
  width: 100%;
  z-index: -1;
  opacity: 0.5;
}
.content-container-terms {
  position: relative;
  top: -120px;
  left: 0;
  width: 100%;
  display: flex;
  padding: 190px 192px;
  flex-shrink: 0;
  border-radius: 128px 128px 0px 0px;
  border: 1px solid #dbdbdb;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(100px);
  font-family: "Hanken Grotesk";
  font-style: normal;
  line-height: 35px;
  z-index: 1;
}

.content-container-terms p {
  background: linear-gradient(270deg, #cc9866 0%, #651498 79.79%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Hanken Grotesk";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  text-decoration-line: underline;
  margin-bottom: 20px;
}
.content-contact-us {
  color: #515151;
  font-family: "Hanken Grotesk";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
}

.category {
  color: #515151;
  font-family: "Hanken Grotesk";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  margin-bottom: 20px;
}

.content {
  color: #515151;
  font-family: "Hanken Grotesk";
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  margin-bottom: 20px;
}
.pricing-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pricing-title {
  color: #000;
  text-align: center;
  font-family: "Hanken Grotesk";
  font-size: 88px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 95px;
}

.pricing-img {
  height: 636px;
  width: 1300px;
  border-radius: 50px;
}

.pricing-tab {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
  /* margin-top: 313px; */
}

.divider {
  width: 6px;
  height: 38px;
  border-radius: 42px;
  background: #b1b1b1;
  margin: 0 40px;
  position: relative;
  top: -15px;
}
.terms-of-use p {
  color: #fff;
  text-align: center;
  font-family: "Hanken Grotesk";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29.252px;
}
.pricing-gradient-container {
  width: 100%;
  height: 1071px;
  flex-shrink: 0;
  background: linear-gradient(0deg, #cc9866 -31.55%, #651498 71.76%);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 272px 177.048px 414px 177px;
  flex-direction: column;
  align-items: center;
  gap: 79px;
}
.pricing-gradient-container h1 {
  width: 834px;
  color: #fff;
  text-align: center;
  font-family: "Hanken Grotesk";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px; /* 112.5% */
}
.pricing-gradient-container p {
  width: 900px;
  color: #fff;
  text-align: center;
  font-family: "Hanken Grotesk";
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 29.252px; /* 121.882% */
}
.price-feature-title{
  font-size: 21px;
   font-weight: 700; 
}
.price-feature-price{
  font-size: 35px;
   font-weight: 500; 
}
.feature-item-price{
  font-size: 18px;
} 
.fetures-list-of-items-0{
  margin-bottom: 130px;
}
.fetures-list-of-items{
  margin-bottom: 44px;

}

.fetures-list-of-items-bottom-0{
  margin-bottom: 34px;
}
.fetures-list-of-items-bottom{
  margin-bottom: 44px;
}
.feature-category {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.feature-category h4 {
  color: #000;
  font-family: "Hanken Grotesk";
  font-size: 17.54px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 65px;
}
.feature-category span {
  color: #cc9866;
  font-family: "Hanken Grotesk";
  font-size: 18.312px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  text-align: left;
  margin-bottom: 44px;
  width: 334px;
}
.feature-category-suppliers {
  display: flex;
  justify-content: center;
  align-items: center;
}
.feature-category-suppliers h4 {
  color: #000;
  font-family: "Hanken Grotesk";
  font-size: 17.54px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 48px;
  width: 370px;
  text-align: left;
}
.feature-category-suppliers .category-item {
  color: #cc9866;
  font-family: "Hanken Grotesk";
  font-size: 18.312px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  text-align: center;
  margin-bottom: 27px;
  width: 182px;
  justify-content: center;
  align-items: center;
  margin-top: 0;
}
.main-features-container-pricing {
  position: relative;
  top: -120px;
  left: 0;
  width: 100%;
  flex-shrink: 0;
  border-radius: 128px 128px 0px 0px;
  border: 1px solid #dbdbdb;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(100px);
  font-family: "Hanken Grotesk";
  font-style: normal;
  line-height: 26px;
  z-index: 1;
}

.features-container-pricing {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 150px;
  margin-top: 166px;
}
.features-container-pricing-suppliers {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 300px;
  margin-top: 166px;
  margin-bottom: 350px;
}
.feature-column h2 {
  text-align: left;
  margin-bottom: 57px;
}
.feature-column-suppliers  h2 {
  text-align: left;
  margin-bottom: 57px;
    }

.feature-category h5 {
  color: #000;
  text-align: center;
  font-family: "Hanken Grotesk";
  font-size: 35.08px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.gradient-join-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}
.gradient-join-btn button {
  display: flex;
  width: 209.115px;
  height: 55.764px;
  padding: 8.713px;
  justify-content: center;
  align-items: center;
  gap: 8.713px;
  flex-shrink: 0;
  color: #fff;
  border-radius: 37.466px;
  background: linear-gradient(270deg, #cc9866 0%, #651498 79.79%);
  border: none;
  margin: 20px 0 248px 0;
  font-family: "Lato" !important;
}

.diverse-suppliers-container-pricing {
  border-radius: 36px;
  background: rgba(0, 0, 0, 0.17);
  backdrop-filter: blur(151.5440216064453px);
  width: 1202px;
  height: 684.392px;
  padding: 80px 60px;
  display: flex;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 350px;
}
.diverse-suppliers-container-pricing span {
  width: 500.354px;
  color: #000;
  font-family: "Hanken Grotesk";
  font-size: 46.01px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-align: left;
  margin-bottom: 64px;
}

.list-of-diversity-pricing {
  color: #000;
  text-align: center;
  font-family: "Hanken Grotesk";
  font-size: 19.171px;
  font-style: normal;
  font-weight: 400;
  line-height: 37.383px; /* 195% */
}

.subscribe-button-container {
  position: relative;
  display: inline-block;
  margin-top: 80px;
  font-family: "Lato" !important;
}

.subscribe-input-field {
  display: flex;
  width: 521px;
  height: 66px;
  padding: 0px 355px 0px 38px;
  align-items: center;
  flex-shrink: 0;
  border-radius: 46px;
  background: rgba(0, 0, 0, 0.1);
  outline: none;
  border: none;
  color: #4b4b4b;
}
.subscribe-footer-pricing-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: inline-flex;
  padding: 27px 38px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 46px;
  background: linear-gradient(270deg, #cc9866 0%, #651498 79.79%);
  outline: none;
  border: none;
  color: #fff;
  font-family: "Lato" !important;
}

.suppliers-pricing-container p {
  color: #000;
  text-align: center;
  margin-top: 139px;
  margin-bottom: 62px;
  font-family: "Hanken Grotesk" !important;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.suppliers-pricing-container button {
  display: flex;
  width: 209.115px;
  height: 55.764px;
  padding: 8.713px;
  justify-content: center;
  align-items: center;
  gap: 8.713px;
  flex-shrink: 0;
  border-radius: 37.466px;
  background: var(--DEI, linear-gradient(270deg, #cc9866 0%, #651498 79.79%));
  border: none;
  color: #fff;
  font-family: "Lato";
}

.download-beta-pdf {
  /* position: relative; */
  width: 273px;
  height: 56px;
  flex-shrink: 0;
  border-radius: 28px;
  background: #fff;
  color: #0e8080;
  text-align: center;
  font-family: "Lato";
  font-size: 13.941px;
  border: 3px solid #0e8080;
}

.pricelist-get-started-btn {
  display: flex;
  width: 149.09px;
  height: 49.112px;
  padding: 8.77px;
  justify-content: center;
  align-items: center;
  gap: 8.77px;
  flex-shrink: 0;
  border-radius: 14.032px;
  background: #fff;
  color: #000;
  text-align: center;
  font-size: 21.048px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  border: none;
  margin-top: 20px;
}

.active-colomn {
  width: 218px;
  height: 867px;
  flex-shrink: 0;
  border-radius: 21.925px;
  background: #fff;
  border-radius: 21.925px;
  background: #fff;
  color: #000;
  /* padding-top: 10px; */
}

.company-page-title {
  width: 834px;
  color: #fff;
  text-align: center;
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px;
  font-family: "Hanken Grotesk";
  margin-bottom: 200px;
}

.company-about-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  top: -120px;
  left: 0;
  width: 100%;
  display: flex;
  padding: 190px 192px;
  flex-shrink: 0;
  border-radius: 128px 128px 0px 0px;
  border: 1px solid #dbdbdb;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(100px);
  font-family: "Hanken Grotesk";
  font-style: normal;
  line-height: 35px;
  z-index: 1;
}

.blue-animated-container {
  /* width: 100%; */
  height: 794px;
  background: var(
    --Gradient-3,
    linear-gradient(0deg, #0e8080 0%, #310274 100%)
  );
  display: flex;
  justify-content: center;
  align-items: center;
}
.blue-animated-container span {
  color: #fff;
  text-align: center;
  font-family: "Hanken Grotesk";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 767px;
}

.founders-container {
  display: flex;
  flex-direction: column;
  padding: 100px 60px;
}
.founders-container span {
  color: #0e8080;
  text-align: center;
  font-family: "Hanken Grotesk";
  font-size: 64px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px; /* 112.5% */
}
.arrows-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 50px;
}

.founders-list {
  display: flex;
  flex-direction: row;
  gap: 50px;
}
.subscribe-to-mailing-list{
  font-size: 64px;
}

.founder-info-container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.founder-info-container-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
 
}
.founder-info-container-details h2 {
  color: #010d0d;
  text-align: center;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
}
.founder-info-container-details h4 {
  color: #6c6c6c;
  text-align: center;
  font-family: Lato;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.4px;
  width: 208px;
}

.sponsor-container {
  overflow: hidden;
  margin: 25px 200px 400px 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.sponsor-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  animation: slide 20s linear infinite;
}

.sponsor-image {
  width: 228px;
  height: 70px;
  margin-right: 50px;
}

@keyframes slide {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-912px);
  }
}

/* .animated {
  color: #8E8E8E; 
  background: var(--DEI, linear-gradient(270deg, #CC9866 0%, #651498 79.79%));
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

.founder-info-container {
  position: relative;
  width: 451px;
  height: 506px;

}

.founder-bio {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  color: #565656;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 28px;
  padding: 20px;
  box-sizing: border-box;
  z-index: 1;
  opacity: 0;

}




@media (max-width: 767px) {

  .pricing-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
  }
  .pricing-title {
    color: #000;
    text-align: center;
    font-family: "Hanken Grotesk";
    font-size: 31px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 55px;
  }
  
  .pricing-img {
    height: 230px;
    width: 568px;
    border-radius: 30px;
  }

  .divider {
    width: 4px;
    height: 27px;
    border-radius: 42px;
    background: #b1b1b1;
    margin: 0 20px;
    position: relative;
    top: -5px;
  }

  .pricing-gradient-container {
    width: 100%;
    height: 621px;
    flex-shrink: 0;
    background: linear-gradient(0deg, #cc9866 -31.55%, #651498 71.76%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 272px 177.048px 414px 177px;
    flex-direction: column;
    align-items: center;
    gap: 79px;
  }
  .pricing-gradient-container h1 {
    width: 373px;
    color: #fff;
    text-align: center;
    font-family: "Hanken Grotesk";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px; /* 112.5% */
  }
  .pricing-gradient-container p {
    width: 373px;
    color: #fff;
    text-align: center;
    font-family: "Hanken Grotesk";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 121.882% */
  }



  .main-features-container-pricing {
    position: relative;
    top: -120px;
    left: 0;
    width: 100%;
    flex-shrink: 0;
    border-radius: 32px 32px 0px 0px;
    border: 1px solid #dbdbdb;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(100px);
    font-family: "Hanken Grotesk";
    font-style: normal;
    line-height: 26px;
    z-index: 1;
  }
  
  .features-container-pricing {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 100px;
  }
  .features-container-pricing-suppliers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 50px;
    margin-bottom: 100px;
  }
  .feature-column h2 {
    text-align: left;
    margin-bottom: 57px;
    width: 142px;
  }
  .feature-column-suppliers  {
width: 142px;

  }
  .feature-column-suppliers  h2 {
    text-align: left;
    margin-bottom: 57px;
      }
  .feature-category h5 {
    color: #000;
    text-align: center;
    font-family: "Hanken Grotesk";
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 216px;
  }
  
  .gradient-join-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .gradient-join-btn button {
    display: flex;
    width: 181px;
    height: 48px;
    padding: 8.713px;
    justify-content: center;
    align-items: center;
    gap: 8.713px;
    flex-shrink: 0;
    color: #fff;
    border-radius: 37.466px;
    background: linear-gradient(270deg, #cc9866 0%, #651498 79.79%);
    border: none;
    margin: 20px 0 100px 0;
    font-family: "Lato" !important;
  }


  .feature-category {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .feature-category h4 {
    color: #000;
    font-family: "Hanken Grotesk";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 63px;
    width: 127px;
    text-align: left;
  }
  .feature-category span {
    color: #cc9866;
    font-family: "Hanken Grotesk";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    text-align: left;
    margin-bottom: 38px;
    width: 155px;

  }


  .diverse-suppliers-container-pricing {
    border-radius: 36px;
    background: rgba(0, 0, 0, 0.17);
    backdrop-filter: blur(151.5440216064453px);
    /* width: 1202px; */
    height: 862px;
    padding: 80px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
    margin-bottom: 100px;
  }
  .diverse-suppliers-container-pricing span {
    width: 319px;
    color: #000;
    font-family: "Hanken Grotesk";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 64px;

  }
  
  .list-of-diversity-pricing {
    color: #000;
    text-align: center;
    font-family: "Hanken Grotesk";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 30px;
    line-height: 27px; /* 207.692% */
  }


  .pricelist-get-started-btn {
    display: flex;
    width: 105px;
    height: 42px;
    padding: 8.77px;
    justify-content: center;
    align-items: center;
    gap: 8.77px;
    flex-shrink: 0;
    border-radius: 14.032px;
    background: #fff;
    color: #000;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    border: none;
    margin-top: 20px;
  }

  .suppliers-pricing-container p {
    color: #000;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 62px;
    font-family: "Hanken Grotesk" !important;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .suppliers-pricing-container button {
    display: flex;
    width: 181px;
    height: 48px;
    padding: 8.713px;
    justify-content: center;
    align-items: center;
    gap: 8.713px;
    flex-shrink: 0;
    border-radius: 37.466px;
    background: var(--DEI, linear-gradient(270deg, #cc9866 0%, #651498 79.79%));
    border: none;
    color: #fff;
    font-family: "Lato";
    font-size: 13px;
  }


  .feature-category-suppliers {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .feature-category-suppliers h4 {
    color: #000;
    font-family: "Hanken Grotesk";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 50px;
    width: 216px;
    text-align: left;
  }
  .feature-category-suppliers .category-item {
    color: #cc9866;
    font-family: "Hanken Grotesk";
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    text-align: center;
    margin-bottom: 20px;
    width: 107px;
    justify-content: center;
    align-items: center;
    margin-top: 0;
  }

  .download-beta-pdf {
    /* position: relative; */
    width: 228px;
    height: 33px;
    flex-shrink: 0;
    border-radius: 22px;
    background: #fff;
    color: #0e8080;
    text-align: center;
    font-family: "Lato";
    font-size: 12px;
    border: 1px solid #0e8080;
  }


  .company-page-title {
    width: 356px;
    color: #fff;
    text-align: center;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    font-family: "Hanken Grotesk";
    /* margin-bottom: 200px; */
  }


  .company-container {
    width: 100%;
    height: 328px;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }


  .company-about-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: -180px;
    left: 0;
    width: 100%;
    display: flex;
    padding: 50px;
    flex-shrink: 0;
    border-radius: 32px 32px 0px 0px;
    border: 1px solid #dbdbdb;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(100px);
    font-family: "Hanken Grotesk";
    font-style: normal;
    line-height: 35px;
    z-index: 1;
  }


  .blue-animated-container span {
    color: #fff;
    text-align: center;
    font-family: "Hanken Grotesk";
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 263px;
  }


.blue-animated-container {
  /* width: 100%; */
  height: 272px;
  background: var(
    --Gradient-3,
    linear-gradient(0deg, #0e8080 0%, #310274 100%)
  );
  display: flex;
  justify-content: center;
  align-items: center;
}


.founders-container span {
  color: #0e8080;
  text-align: center;
  font-family: "Hanken Grotesk";
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  line-height: 72px; /* 112.5% */
}

.founders-container {
  display: flex;
  flex-direction: column;
  padding: 42px 24px;
}


.founder-info-container-details h2 {
  color: #010d0d;
  text-align: center;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.26px;
}
.founder-info-container-details h4 {
  color: #6c6c6c;
  text-align: center;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.26px;
  width: 140px
}

.founder-info-container {
  position: relative;
  width: 208px;
  height: 250px;

}


.founder-bio {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  color: #565656;
  font-family: Lato;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
  text-align: left;
  line-height: 12px;
  padding: 5px;
  box-sizing: border-box;
  z-index: 1;
  opacity: 0;

}

.founder-info-container-details {
  width: 180px;
}


.sponsor-container {
  overflow: hidden;
  margin: 25px 30px 150px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
}
.sponsor-image {
  width: 80px;
  height: 18px;
  margin-right: 20px;
}


.terms-of-use {
  font-size: 30px;

}


.terms-container {
  /* position: relative; */
  width: 100%;
  height: 318px;
}


.content-container-terms {
  top: -50px;
  padding: 70px 22px 0px 23px;
  border-radius: 32px 32px 0px 0px;
  line-height: 18px;

}

.category {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 10px;
  text-align: left;
}

.content {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 10px;
  width: 385px;
}

.content-container-terms p {
  font-size: 13px;
  line-height: 18px;
  margin-bottom: 10px;
}


.content-contact-us {
  font-size: 13px;
  line-height: 18px;
}
}


@media screen and (min-width: 769px) and (max-width: 1280px) {
  .pricing-title {
    font-size: 71px;
    margin-bottom: 78px !important;
    margin-top: 134px !important;
  }
  
  .pricing-img {
    height: 518px;
    width: 1150px;
    border-radius: 50px;
  }
  .pricing-gradient-container h1 {
    font-size: 52.22px;
  line-height: 58.747px;
  width: 680.491px;
  }
  .pricing-gradient-container p {
    font-size: 19.582px;
    line-height: 23.868px;
  width: 735px;
  }

  .diverse-suppliers-container-pricing {
    border-radius: 36px;
    background: rgba(0, 0, 0, 0.17);
    backdrop-filter: blur(151.5440216064453px);
    width: 980px;
    height: 558px;

    margin-bottom: 250px;
  }
  .diverse-suppliers-container-pricing span {
    width: 408.257px;
    font-size: 37.541px;
  }
  .list-of-diversity-pricing{
    font-size: 15.642px;
line-height: 30.502px;
  }
  .pink-circle{
    width: 465.9px !important;
height: 465.9px !important;

  }
  .pricing-table{
    gap:50px;
  }

  .active-colomn {
    width: 185px;
    height: 732.351px;
  }
.price-label{
  font-size: 28px !important;
}
.price-feature-title{
  font-size: 17px;
   font-weight: 700; 
}

.fetures-list-of-items-0{
  margin-bottom: 120px;
}
.fetures-list-of-items{
  margin-bottom: 28px;

}

.fetures-list-of-items-bottom-0{
  margin-bottom: 42.26px;
}
.fetures-list-of-items-bottom{
  margin-bottom: 28.6px;
}

.pricing-gradient-container {
  height: 1037px !important ;
}
.suppliers-pricing-container p {
  font-size: 52.22px;
  margin-top: 100px;
  margin-bottom: 50px;
}
.suppliers-pricing-container button{
  width: 170.625px;
height: 45.5px;
padding: 7.109px;

}
.subscribe-to-mailing-list{
  font-size: 52px;
}
.features-container-pricing-suppliers {
  margin-bottom: 150px;
}

.company-sign-up-btn{
  width: 122.281px;
height: 45.5px;
padding: 7.109px;
}

.company-page-title {
  font-size: 52.22px;
font-style: normal;
font-weight: 700;
line-height: 58.747px;
width: 680.491px;
}

.company-about-container {
  top: -180px;
  left: 0;
  width: 100%;
  display: flex;
  padding: 100px;
  border-radius: 52.22px 52.22px 0px 0px;
}
.company-about-container{
  height: 1524px !important ;
}
.animated{
  font-size: 52.22px !important;
line-height: 58.747px !important;
}
.company-fouded{
  width: 647.853px !important;
  font-size: 16.319px !important;
  line-height: 23.662px !important;
}
.blue-animated-container span {
  font-size: 52.22px;
}

.founder-info-container{
width: 369.619px;
height: 409.6px;
}

.founder-info-container {
  width: 367px !important;


}

.founder-bio {
  font-size: 14px;
  width: 367px;
  line-height: 24px;


}

.terms-of-use {
  font-size: 71px;

}


.terms-container {
  width: 100%;
  height: 487px;
}


.content-container-terms {
  top: -100px;
  padding: 155.028px 156.658px 0px 156.662px;
  line-height: 18px;
  border-radius: 104.44px 104.44px 0px 0px;
  


}

.category {
  font-size: 16.319px;
  line-height: 21.214px;
  margin-bottom: 10px;
  text-align: left;
}

.content {
  font-size: 14.687px;
  line-height: 21.214px;
  margin-bottom: 10px;
  width: 100%;
}

.content-container-terms p {
  font-size: 14.687px;
  line-height: 21.214px;
}


.content-contact-us {
  font-size: 16px;
  line-height: 35px;
}
}