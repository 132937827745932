

/* .landing-page-container button {
  cursor: none !important;
} */

.sign-up-popup-landing{
  display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 300px;
    background: #fff;
    border-radius: 10px;
    font-family: 'Hanken Grotesk';
    z-index: 9999;
    cursor: pointer;
}

.close-button {
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
  color: #fff;
}
/* 
.overlay-landing {
  position: fixed;
  top: 30%;
  left: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
} */
/* .landing-page-container {
  cursor: none;
} */

.landing-page-container {
  position: relative;
}

.preloader-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.5s ease-out;
}


/* .custom-cursor {
  position: absolute;
  width: 35px;
  height: 45px;
  background-image: url("../../assets/LandingPage/Cursor.png");
  background-repeat: no-repeat;
  pointer-events: none;
  z-index: 9999;
  transition: transform 0.3s ease;

  left: 0;
  pointer-events: none;

} */

/* 
.custom-cursor.hovered {
  transform: scale(1.2);
} */

.sign-up-btn {
  position: relative;
  display: flex;
  width: 149.866px;
  height: 55.764px;
  padding: 8.713px;
  justify-content: center;
  align-items: center;
  gap: 8.713px;
  flex-shrink: 0;
  border-radius: 37.466px;
  background: #0e8080;
  font-family: "Lato" !important;
  color: #fff;
  border: none;
  z-index: 1;
  transition:         0.08s ease-in;
  -o-transition:      0.08s ease-in;
  -ms-transition:     0.08s ease-in;
  -moz-transition:    0.08s ease-in;
  -webkit-transition: 0.08s ease-in;
}

.company-sign-up-btn{
  position: relative;
  display: flex;
  width: 149.866px;
  height: 55.764px;
  padding: 8.713px;
  justify-content: center;
  align-items: center;
  gap: 8.713px;
  flex-shrink: 0;
  border-radius: 37.466px;
  background: #fff;
  font-family: "Lato" !important;
  color: #000;
  border: none;
  z-index: 1;
  transition:         0.08s ease-in;
  -o-transition:      0.08s ease-in;
  -ms-transition:     0.08s ease-in;
  -moz-transition:    0.08s ease-in;
  -webkit-transition: 0.08s ease-in;
}

.sign-up-btn:hover {
  color: #fff;
}

.sign-up-btn:before {
  content: "";
  position: absolute;
  border-radius: 37.466px;
  background: linear-gradient( 270deg, #0E8080 0.17%, #654198 84.24%);
  bottom: 0;
  left: 0;
  top: 0;
  right: 100%;
  z-index: -1;
  -webkit-transition: right 0.5s ease-in;
}

.sign-up-btn:hover:before {
  right: 0;
}

.basic-navbar-nav {
  padding: 40px 123px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  /* cursor: none !important; */
}
.navbar-nav .nav-link {
  color: #717171;
  text-align: center;
  font-size: 13.941px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.279px;
  text-decoration: none;
  /* cursor: none !important; */
  font-family:  Lato !important;
  pointer-events: auto;
}
.company-nav-links .nav-link{
  color: #fff;
  text-align: center;
  font-size: 13.941px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.279px;
  text-decoration: none;
  /* cursor: none !important; */
  font-family:  Lato !important;
}
.background-container > div {
  position: relative;
  z-index: 1;
}

.background-container p {
  color: #636363;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-top: 50px;
}

.background-container button {
  display: flex;
  width: 209.115px;
  height: 55.764px;
  padding: 8.713px;
  justify-content: center;
  align-items: center;
  gap: 8.713px;
  border-radius: 37.466px;
  background: #000;
  color: #fff;
  text-align: center;
  font-size: 13px;
  margin: auto;
  margin-top: 16px;
  border: none;
  font-family: "Lato" !important;
  
}

.dynamic-container-span {
  color: #000;
  text-align: center;
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 88.003px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.background-container {
  position: relative;
  height: 723px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.background-image {
  position: absolute;
  height: 723px;
  top: 0;
  left: 0;
  width: 100%;
  z-index: -2;
  object-fit: cover;
}
.background-video {
  position: absolute;
  margin-bottom: 20px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 1106.568px;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
  flex-shrink: 0;
  border-radius: 41.823px;
}

.text-container {
  z-index: 2;
}


.word{
  font-size: 88.003px;
}
.word.active{
  font-size: 88.003px;
}


.text-container:hover .background-video {
  opacity: 1;
  z-index: -1;
}


.dynamic-container-span,
.word-container,
p {
  transition: color 0.3s ease;
}

.text-container:hover .dynamic-container-span,
.text-container:hover .word-container,
.text-container:hover p{
  color: #fff; 
}
.text-container:hover .word.active {
  color: #fff;
}
.text-container:hover {
  margin-top: 0;
}
.word.active.hovered {
  color: #fff;
}


.text-container:hover button {
  background: #fff;
  color: #000;
}

/* .background-container:hover .background-video {
  opacity: 1;
  z-index: -1;
}

.background-container:hover .dynamic-container-span,
.background-container:hover .word-container,
.background-container:hover p {
  color: #fff; 
}

.background-container:hover .text-container {
  margin-top: 0;
}

.background-container:hover button {
  background: #fff;
  color: #000;
} */

.beta-program-container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  width: 100%;
  padding: 26px 0;
  gap: 37px;
  background: #4aa0a1;
  margin-top: 10px;
  transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
  will-change: transform;
  position: relative;
  font-family:  Lato !important;
  transform: translateZ(0);
  /* -webkit-transform: translateZ(0); */
  -webkit-transform: translate3d(0,0,0);
  /* position: fixed; */
}

.beta-program-container.fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  padding: 10px 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transform: translateZ(0);
  /* -webkit-transform: translateZ(0); */
  -webkit-transform: translate3d(0,0,0);
  
}
.beta-program-container button {
  display: flex;
  height: 64px;
  width: 209px;
  padding: 8.713px;
  justify-content: center;
  align-items: center;
  gap: 8.713px;
  border-radius: 37.466px;
  background: #fff;
  border: none;
  color:#000;
  font-family: "Lato" !important;
}
.beta-program-container.fixed button{
  height: 54px;
}
.supplier-discovery-container {
  display: flex;
  flex-direction: column;
  height: 892.444px;
  background: #654198;
  justify-content: center;
  align-items: center;
}

@keyframes reveal-text-center-to-edges {
  from {
    clip-path: circle(0% at 50% 50%);
  }
  to {
    clip-path: circle(100% at 50% 50%);
  }
}

.text.animate {
  animation: reveal-text-center-to-edges 4s ease forwards;
  clip-path: circle(0% at 50% 50%);
}

@keyframes reveal-text {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0% 0 0);
  }
}

@keyframes reveal-text-top-to-bottom {
  from {
    clip-path: inset(0 0 100% 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.chart-content.animate {
  animation: reveal-text-top-to-bottom 2s ease forwards;
  clip-path: inset(0 0 100% 0);
}

.text-box.animate {
  animation: reveal-text-top-to-bottom 4s ease forwards;
  clip-path: inset(0 0 100% 0);
}

@keyframes reveal-text-top-to-bottom {
  from {
    clip-path: inset(0 0 100% 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.text-top.animate {
  animation: reveal-text-top-to-bottom 2s ease forwards;
  clip-path: inset(0 0 100% 0);
}

@keyframes reveal-text-bottom-to-top {
  from {
    clip-path: inset(100% 0 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.text-bottom.animate {
  animation: reveal-text-bottom-to-top 2s ease forwards;
  clip-path: inset(100% 0 0 0);
}

.supplier-discovery-container span {
  color: #fff;
  text-align: center;
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 66.236px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  width: 816px;
}

.supplier-discovery-container p {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 402.646px;
  margin-top: 20px;
  font-family:  Lato !important;
}

.suppliers-container {
  display: flex;
  flex-direction: column;
  height: 1382px;
  margin: 142px 20px 122px 72px;
  font-family:  Lato !important;
}

.suppliers-container span {
  display: flex;
  color: #000;
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 50px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  width: 540px;
  margin-top: 40px;
  text-align: left;
}

.suppliers-container p {
  display: flex;
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 494px;
  text-align: left;
  margin-bottom: 20px;
}

.join-btn {
  display: flex;
  width: 167px;
  height: 56px;
  padding: 8.7px;
  justify-content: center;
  align-items: center;
  gap: 8.7px;
  flex-shrink: 0;
  border-radius: 37.41px;
  background: #000;
  color: #fff;
  border: none;
  font-family: "Lato" !important;
}


.enterprise-container span {
  color: #000;
  text-align: center;
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 66.12px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 40px;
}
.enterprise-container h1 {
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 20px;
}
.enterprise-container button {
  display: flex;
  width: 149.64px;
  height: 55.68px;
  padding: 8.7px;
  justify-content: center;
  align-items: center;
  gap: 8.7px;
  color: #fff;
  border-radius: 37.41px;
  background: #000;
  border: none;
  font-family: "Lato" !important;
}
.chart-container {
  height: 1496px;
  flex-shrink: 0;
  background: #010d0d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.chart-content {
  width: 542px;
  /* text-align: left; */
  padding-top: 70px;
  position: absolute;
  top: 70px;
  left: 50%;
  transform: translateX(-50%);
}

.chart-content span {
  color: #fff;
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 56px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}


.ai-image {
  width: 659px;
  height: 558px;
  }

.text-box {
  display: inline-flex;
  width: 238px;
  height: 80px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 22px;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(8px);
  color: #fff;
  margin-right: 10px;
}

.text-box span {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  display: flex;
  align-items: center;
}

.text {
  visibility: visible;
}

.text-box:hover .text {
  visibility: visible;
  font-family:  Lato !important;
}

.expanded-content {
  display: none;
  font-family:  Lato !important;
}
.expanded-content p {
  font-family:  Lato !important;
  color: #FFF;

text-align: center;
font-size: 36px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.text-box:hover .expanded-content {
  display: block;
}

.text-box:hover {
  width: 248px;
  height: 238px;
  padding: 43px 5px 13px 5px;
  display: flex;
  flex-direction: column;
}

 .signup-triger-container {
  height: 957.87px;

}

.text-content {
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 20px;
  color: white;
  opacity: 1;
  transition: opacity 0.5s ease, transform 0.5s ease;

}



.signup-triger-container span {
  display: flex;
  color: #000;
  text-align: center;
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 60.9px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  position: relative;


}


.signup-content {
  width: 512px;
  text-align: center;
  padding-top: 70px;
}

.signup-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 100px;
  height: 490px;

}
/* .image-section{
  margin-top: 100px;
} */

.icon-text-container {
  display: inline-block;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
}

.animated-icon {
  margin-right: 13px;
  position: absolute;
  top: 5px;
  left: -40px;
  width: 32px;
  height: 32px;
  opacity: 0;
}
 .text-content {

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  opacity: 0;
}
.footer {
  flex-shrink: 0;
  background: #fff;
  /* margin-top: 200px; */
  margin-bottom: 40px;
  flex-direction: column;
}
.footer-column h4 {
  color: #000;
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 17.4px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-bottom: 27px;
  text-align: left;
}
.footer-column li {
  color: #000;
  font-family:"Hanken Grotesk", sans-serif;
  font-size: 13.92px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 30px;
  text-align: left;
  cursor: pointer;
  pointer-events: auto;
}
.running-text {
  color: #0e8080;
  text-align: center;
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 166px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 150px;
  display: flex;
  flex-direction: row;
  animation: runningText 30s linear infinite;
}

@keyframes runningText {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
.running-text-container {
  display: flex;
  align-items: center;
  white-space: nowrap;

}

.eicon {
  margin-left: 5px;
}


.rolling-card span,
.rolling-card p {
  align-self: flex-start;
  text-align: left;
  color: #000 !important;
  font-family:  Lato !important;
}

.rolling-card span {
  font-size: 20px;
  font-weight: 800;
  letter-spacing: 0.4px;
  margin-top: 10px !important;
  margin-bottom: 20px;
}

.rolling-card p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.32px;
  width: 360px;
}

.enterprise-container.in-view .rolling-card {
  opacity: 1;
}

.cube {
  position: absolute;
  color: transparent;
  height: 890.88px;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
}

.word-slider {
  font-size: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.word-container {
  display: flex;
  align-items: center;
  white-space: nowrap;
  color: #000;
  text-align: center;
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 88.003px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  transition: transform 10s ease, opacity 0.5s ease-in-out;
}

.word {
  transition: transform 3s ease, opacity 1s ease;
  opacity: 1;
  animation: scrollUp 3s linear infinite;
  margin: 0 15px;
}

.word.active {
  opacity: 0;
}

@keyframes scrollUp {
  0% {
    transform: translateY(30%);
  }
  100% {
    transform: translateY(-30%);
  }
}

.icon-text-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}


.navbar-nav {
  flex-direction: row !important;
}

.navbar-nav .nav-link {
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.subscribe-button-container {
  position: relative;
  display: inline-block;
  margin-top: 80px;
  font-family: "Lato" !important;
}

.subscribe-input-field {
display: flex;
width: 521px;
height: 66px;
padding: 0px 355px 0px 38px;
align-items: center;
flex-shrink: 0;
border-radius: 46px;
background: rgba(0, 0, 0, 0.10);
outline: none;
border: none;
color: #4B4B4B;
}

.subscribe-footer-button {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: inline-flex;
  padding: 27px 38px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 46px;
background: #0E8080;
outline: none;
border: none;
color: #FFF;
font-family: "Lato" !important;
}


@media (max-width: 991px) {
  .navbar-nav {
    flex-direction: row !important;
  }
}

@media (max-width: 768px) {

  html, body {
    overflow-x:hidden 
  }
  .navbar-nav {
    flex-direction: column !important;
    align-items: center;
  }

  .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0;
  }
}


.enterprise-container {
  height: 890.88px;
  justify-content: center;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: transparent;
}

.horizontal {
  display: flex;
  gap:20px;
  bottom: 20px;
}

.horizontal .rolling-card {
  display: flex;
  padding: 10px 20px 30px 20px;
  flex-direction: column;
  align-items: center;
  text-align: left;
  border-radius: 13.92px;
  background: rgba(255, 255, 255, 0.31);
  backdrop-filter: blur(19.879501342773438px);
  width: 449px;
  height: 539px;
  margin-right: 10px;
  visibility: hidden;
  opacity: 0;
}

.horizontal-scoll-wrapper{
  position: absolute;
  left: 249px;
}




/* landingPage.css */

/* Style for the hamburger menu icon */
.hamburger-menu-icon {
  display: none; /* Hide hamburger menu icon by default on desktop */
}

/* Style for the mobile navbar links */
.mobile-nav-links {
  display: none; /* Hide mobile links by default */
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 100px;
}
.mobile-nav-links .nav-link{
  color: #000;
font-family: "Helvetica Neue";
font-size: 31px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 47px;
}
.mobile-links-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw; /* Cover the entire width of the viewport */
  height: 100vh; /* Cover the entire height of the viewport */
  background-color: white; /* Adjust as needed */
  z-index: 1000; /* Ensure the menu appears on top of other content */
  padding: 60px; /* Add padding as needed */

}

.close-menu {
  position: absolute;
  top: 60px;
  right: 20px;
  cursor: pointer;
  font-size: 20px;
}
.mobile-basic-navbar {
  padding: 60px 60px 20px 60px;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .basic-navbar-nav .navbar-collapse {
    display: flex;
    flex-direction: column;
  }

  .hamburger-menu-icon {
    display: block; /* Show hamburger menu icon on mobile */
    cursor: pointer;
    width: 30px;
    height: 30px;
    margin-right: 15px;
  }

  .mobile-nav-links {
    display: flex; /* Show mobile links when toggled */
    flex-direction: column; /* Display links in a column */
  }

  .sign-up-btn {
    display: none; /* Hide sign-up button on mobile */
  }

  /* Style for expanded navbar */
  .basic-navbar-nav .navbar-collapse.collapsing,
  .basic-navbar-nav .navbar-collapse.show {
    display: flex !important;
  }



  .background-container p {
    color: #636363;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 50px;
    width: 247px;
  }
  
  .background-container button {
    display: flex;
    width: 163px;
    height: 44px;
    padding: 8.713px;
    justify-content: center;
    align-items: center;
    gap: 8.713px;
    border-radius: 37.466px;
    background: #000;
    color: #fff;
    text-align: center;
    font-size: 13px;
    margin: auto;
    margin-top: 16px;
    border: none;
    font-family: "Lato" !important;
    
  }
  
  .dynamic-container-span {
    color: #000;
    text-align: center;
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 31px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  
  .background-container {
    height: 600px;
    position: relative;
    height: 523px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  .background-image {
    /* opacity: 0; */
    position: absolute;
    height: 723px;
    top: 300px;
    left: 0;
    z-index: -2;
    object-fit: cover;
  }
  .background-video {
    position: absolute;
    margin-bottom: 20px;
    top: 50%;
    left: 50%;
    height: 400px;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease;
    flex-shrink: 0;
    border-radius: 41.823px;
    width: fit-content;
    object-fit: contain;
  }
  
  .text-container {
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .word-slider {
    font-size: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .word-container {
    display: flex;
    align-items: center;
    white-space: nowrap;
    color: #000;
    text-align: center;
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 31px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    transition: transform 10s ease, opacity 0.5s ease-in-out;
  }

  .word{
    font-size: 31px;
  }
  .word.active{
    font-size: 31px;
  }

  
  
  .beta-program-container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    width: 100%;
    height: 70px;
    padding: 10px 5px;
    /* gap: 37px; */
    background: #4aa0a1;
    margin-top: 10px;
    transition: all 0.5s cubic-bezier(0.42, 0, 0.58, 1);
    will-change: transform;
    position: relative;
    font-family:  Lato !important;
    transform: translate3d(0,0,0);
    -webkit-transform: translate3d(0,0,0);
  }
  /* .beta-program-container.fixed {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 10px;
    height: 70px;
    overflow: auto;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transform: translate3d(0,0,0);

    -webkit-transform: translate3d(0,0,0);
  } */
  
  .beta-program-container button {
    display: flex;
    height: 40px;
    /* width: 131px; */
    padding: 8.713px;
    justify-content: center;
    align-items: center;
    gap: 8.713px;
    border-radius: 37.466px;
    background: #fff;
    border: none;
    color:#000;
    font-family: "Lato" !important;
  }
  /* .beta-program-container.fixed button{
    height: 40px;
  }  */


  .supplier-discovery-container {
    display: flex;
    flex-direction: column;
    height: 699px;
    background: #654198;
    justify-content: space-around;
    align-items: center;
  }
  .supplier-discovery-container span{
    width: 386px;
    color: #FFF;
text-align: center;
font-size: 30px;
font-style: normal;
font-weight: 700;
line-height: normal;
margin-bottom: 40px;
margin-top: 40px;
  }
.supplier-discovery-container p {
  font-size: 13px;
  font-weight: 400;
  width: 328px;

}



.suppliers-container {
  display: flex;
  flex-direction: column;
  height: 1685px;
  /* margin: 142px 20px 122px 72px; */
  font-family:  Lato !important;
}

.suppliers-container span {
  display: flex;
  color: #000;
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  width: 332px;
  margin-top: 40px;
  text-align: left;
}

.suppliers-container p {
  display: flex;
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 310px;
  text-align: left;
  margin-bottom: 20px;
}



.enterprise-container {
  height: 453px;
  justify-content: center;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: transparent;
}

.enterprise-container span{
  color: #000;
  width: 378.883px;
text-align: center;
font-family: "Helvetica Neue";
font-size: 28.211px;
font-style: normal;
font-weight: 700;
line-height: normal;
}

.enterprise-container p {
  color: #000;
text-align: center;
font-size: 13px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.chart-container {
  height: 571px;
  flex-shrink: 0;
  background: #010d0d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-bottom: 100px;
}

.chart-content {
  width: 264px;
  /* text-align: left; */
  padding-top: 20px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 50px;
}

.chart-content span {
  color: #fff;
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}
.text-box {
  display: flex;
  width: 116.331px;
  height: 36.496px;
  justify-content: center;
  align-items: center;
  border-radius: 10.036px;
  background: rgba(255, 255, 255, 0.10);
  backdrop-filter: blur(3.649609327316284px);
  color: #fff;
  margin-right: 10px;
}

.text-box span {
  color: #FFF;
  display: flex;
  text-align: center;
  font-size: 9.525px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: "Hanken Grotesk", sans-serif;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.text-box:hover {
  width: 140px;
  height: 120px;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.signup-triger-container span {
  display: flex;
  color: #000;
  text-align: center;
  font-family: "Hanken Grotesk", sans-serif;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  position: relative;
  margin-bottom: 20px;


}


.signup-content {
  width: 252px;
  text-align: center;
  padding-top: 0;
}

.signup-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  height: 400px;

}

.animated-icon {
  margin-right: 13px;
  position: absolute;
  top: -15px;
  left: -40px;
  width: 32px;
  height: 32px;
  opacity: 0;
}

.cube {
  position: absolute;
  color: transparent;
  height: 400px;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  opacity: 0;
}
.rolling-card{
  height: 380px;
}

.rolling-card span,
.rolling-card p {
  align-self: flex-start;
  text-align: left;
  color: #000 !important;
  font-family:  Lato !important;
  width: 330px;
}

.rolling-card span {
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 0.28px;
  margin-top: 10px !important;
  margin-bottom: 10px;
}

.rolling-card p {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.22px;
  width: 253px;
}

.horizontal{
  height: 400px;
}

.text-container:hover .background-video {
  opacity: 0;
  z-index: -1;
}


 .dynamic-container-span,
.word-container,
p {
  transition: color 0.3s ease;
}

.text-container:hover .dynamic-container-span,
.text-container:hover .word-container,
.text-container:hover p{
  color: #000; 
} 
.text-container:hover .word.active {
  color: #000;
}
.text-container:hover {
  margin-top: 0;
}
.word.active.hovered {
  color: #000;
}


.text-container:hover button {
  background: #000;
  color: #fff;
} 

.subscribe-button-container {
  position: relative;
  display: inline-block;
  margin-top: 30px;
  font-family: "Lato" !important;
  margin-bottom: 50px;
}
.running-text {
  margin-top: 100px;
  margin-bottom: 50px;

}


 .signup-triger-container {
  height: 596px;
  margin-bottom: 150px;

} 

}


/* @media screen and (min-width: 1280px) {

  .dynamic-container-span {
    color: #000;
    text-align: center;
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 60.003px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
  }



} */

 @media screen and (min-width: 769px) and (max-width: 1280px) {
  .dynamic-container-span {
    font-size: 71px;

}

.sign-up-btn {
  width: 122.281px;
  height: 45.5px;
  padding: 7.109px;
}

.word{
  font-size: 71px;
}

.word-slider {
  font-size: 71px;

}
.word-container {
  font-size: 71px;

}
.word.active{
  font-size: 71px;
}

.background-video {
  width: 950px;

}
.beta-program-container{
  font-size: 16.319px;

}

.beta-program-container button {
  display: flex;
  height: 52px;
  width: 180px;
  padding: 7.109px;

}
.beta-program-container.fixed button{
  height: 48px;
}

.supplier-discovery-container span {
  font-size: 54px;
  font-weight: 700;
  width: 665px;
}
.supplier-discovery-container p {
  font-size: 13px;
  width: 328px;

}

.ai-image {
width: 537.702px;
height: 422.655px;
}

.suppliers-container{
 height: 1127px !important;
}

.suppliers-container span {
  font-size: 40px;
  font-weight: 700;
  width: 440px;
}

.suppliers-container p {
  font-weight: 400;
  width: 403px;

}

.join-btn {
  width: 136.261px;
  height: 45.692px;
  padding: 7.099px;
  gap: 7.099px;
}


.enterprise-container span {
  font-size: 53.95px;
}
.enterprise-container h1 {
  font-size: 14px;

}
.enterprise-container button {
  width: 122.097px;
  height: 45.431px;
  padding: 7.099px;
}


.rolling-card span {
  font-size: 16px;
}

.rolling-card p {
  font-size: 13px;
  font-weight: 400;
  letter-spacing: 0.20px;
  width: 320px;
}

.horizontal .rolling-card {
  width: 369px !important;
  height: 459px;

}
.horizontal-scoll-wrapper{
  top: 100px;
}
.enterprise-container{
  height: 726px !important;
}
.cube{
  height: 726px;
}

.chart-content {
  width: 442px;
  padding-top: 70px;
  position: absolute;
  top: 57px;
  left: 50%;
  transform: translateX(-50%);
}

.chart-content span {
  font-size: 45px;
}

.image-container{
  width: 548.309px;
  height: 467.531px;
}
.signup-triger-container span{
  font-size: 49.691px;



}
.animated-icon{
  top:0
}
.supplier-icon {
  width: 430.814px;
height: 401.441px;

}
.signup-section {
  height: 400px;

}


} 


