
.imported-files-list {
  margin-top: 20px;
  /* margin-bottom: 85px; */
  /* padding: 0 44px 32px 44px; */
}

.imported-file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  
}
.file-icons{
  display: flex;
  gap: 21px;
}
.drag-drop-container {
  display: flex;
  border: 1px dashed #7A7A7A;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
}  
.drop-zone{
  display: flex;
  justify-content: center;
}

.delete-confirm-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.delete-preview-content {
display: flex;
flex-direction: column;
border-radius: 8px;
background: #FFF;
  /* padding: 20px; */
  width: 521px;
height: 242px;
padding: 66px 72px 30px 72px;
  /* overflow-y: auto; */
  /* border-radius: 8px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  /* justify-content: flex-end; */
}

.request-preview-content{
  display: flex;
flex-direction: column;
border-radius: 8px;
background: #FFF;
  padding: 20px 20px 20px 32px;
  width: 521px;
height: 242px;
/* padding: 66px 72px 30px 72px; */
}


.request-info-textarea{
  display: flex;
  width: 457px;
  height: 86px;
  padding: 13.582px 180.563px 13.582px 12.783px;
  align-items: center;
  gap: 7.99px;
  border-radius: 6.392px;
  border: 1px solid #D7D7D7;
  background: #F6F6F6;
  margin-top: 10px;
  resize: none;
  
  }