body {
  font-family: "Lato", sans-serif;
}

.info-boxes {
  display: flex;
  justify-content: space-between;
  border-radius: 8px;
  border: 1px solid #ddd;
  align-items: stretch;
}

.info-box {
  flex: 1;
  padding: 10px;
  text-align: center;
  border-left: 1px solid #ddd;
  /* white-space: nowrap;  */
}
.info-box:first-child {
  border-left: none;
}
/* .info-box:nth-child(2) {
  white-space: normal; 
} */
.grey-info {
  color: #7b7b7b;
}

.info-box h3 {
  margin-bottom: 10px;
}
.full-user-profile-card-body {
  padding: 20px 0 0 38px;
}
.user-profile-subtitle {
  color: #1d2739;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  align-items: center;
  margin: 0;
  text-align: left;
}
.supplier-rating {
  display: flex;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 0 10px;
  margin-left: 10px;
}
.hire-btn {
  height: 39px;
  border-radius: 8px;
  background: #0e8080;
  border: none;
  color: #fff;
  align-items: center;
  text-align: center;
}
.tab-content {
  color: #7b7b7b;
  text-align: start;
  margin-top: 20px;
  margin-bottom: 44px;
}
.integration-list-subtitle {
  margin: 0;
  display: flex;
  font-size: 16px;
}
.main-contact-subtitle {
  color: #7b7b7b;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
}
.company-full-info-container {
  /* display: inline-flex; */
  width: 98%;
  margin: 20px 5px 56px 0;
}
.user-profile-card-body {
  padding: 20px 0 20px 20px;
  margin-top: 50px;
  max-height: 90vh;
  overflow-y: auto;
  margin-right: 10px;
}
.user-profile-card-body::-webkit-scrollbar {
  width: 0;
}
.open-profile-button {
  display: flex;
  height: 36px;
  padding: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  color: #1d2739;
  background: #fff;
  border: 1px solid rgba(221, 221, 221, 0.87);
}
.company-info-container {
  width: 98%;
  margin: 20px 5px 42px 0;
}
.talent-certificates {
  margin-bottom: 44px;
}

.save-to-mng-tab-container {
  display: flex;
  align-content: flex-end;
  width: 197px;
  padding: 7px 10px 7px 9px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: none;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.6);
  box-shadow: 0px 4px 91.2px -4px rgba(0, 0, 0, 0.1);
  color: #fff;
  text-align: center;
  font-family: Lato;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.contact-hover {
  display: flex;

  justify-content: center;
  width: 120px;
  padding: 11px 27px 12px 13px;
  align-items: center;
  border-radius: 7px;
  border: 1px solid #ddd;
  background: #fff;
  box-shadow: 0px 4px 91.2px -4px rgba(0, 0, 0, 0.1);
}

.service-card {
  width: 260px;
  height: 299px;
  flex-shrink: 0;
  border-radius: 16px;
  border: 1px solid #cfcfcf;
  background: #fff;
  margin: 30px 0 30px 0;
}
.service-image {
  display: flex;
  /* width: 259px; */
  height: 156px;
  padding: 41px 0px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  background: #9a9a9a;
  border-radius: 16px 16px 0 0;
}
/* .service-image img{
  width: 100%;
  height: 156px;
  border-radius: 16px 16px 0 0;
} */
.service-content {
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.service-content span {
  color: #1d2739;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.service-content p {
  height: 35.74px;
  display: inline-block;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 46.009px;
  color: #545454;
  font-size: 11.164px;
  font-weight: 400;
  background: #f5f5f5;
  text-align: left;
}

.profile-info-container {
  display: flex;
  margin-bottom: 33px;
}

.profile-info-item {
  flex: 1;
  border-right: 1px solid #ddd;
  /* padding-left: 28px; */
  text-align: center;
}

.profile-info-item:last-child {
  border-right: none;
}

.profile-title {
  display: block;
  color: #1c1c1c;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 8px;
}

.profile-text {
  display: block;
  color: #7b7b7b;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.naics-info-item {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-bottom: 50px;
}
