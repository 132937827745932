ol,
ul {
  list-style: none;
}
/* h2 {
  font-size: 24px;
  margin-bottom: 20px;
} */
body {
  font-family: 'Lato', sans-serif;
}
#page-content-wrapper {
  margin-left: 226px;
  /* display: flex; */
  /* height: 100vh; */
}
/* #page-content-wrapper {
  display: flex;
  margin-left: 226px; 
} */

.dashboard-sidebar {
  position: fixed;
  width: 226px;
  min-height: 100vh !important;
  border-right: none;
  background: var(--Gradient-hor);
}
/* .dashboard-sidebar {
  position: fixed;
  width: 226px;
  min-height: 100vh !important;
  border-right: 2px solid #ebebeb;
  background: #fff;
} */
.disabled {
  color: #7b7b7b;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.49);
  opacity: 1;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

/* Dashboard */

.dashboard-nav-container-main {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  justify-content: space-between;
  padding-bottom: 5px;
  background: linear-gradient(90deg, #CC9866 0%, #651498 99.52%);
}

.dashboard-nav-container-label {
  display: flex;
  justify-content: flex-start;
  font-size: 24px;
  color: #1d2739;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 19px;
}
.dashboard-nav-container-subtitle {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 16px;
  margin-top: 10px;
}

/* Dashboard Notifications */

.custom-notification-container {
  position: fixed;
  width: 383px;
  right: 30px;
  margin-top: 30px;
  z-index: 999;
  border-radius: 11.471px;
  border: 1.147px solid #ebebeb;
  background: #fff;
  box-shadow: 0px 4.588px 104.612px -4.588px rgba(0, 0, 0, 0.1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.custom-notification-container .close-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.custom-notification-container .close-button span {
  font-size: 20px;
  color: #888;
}

.custom-notification-container .close-button:hover span {
  color: #333;
}
.red-dot {
  position: absolute;
  top: 10px;
  left: 15px;
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
}

/* Dashboard Side Nav */

/* .dashboard-sidebar {
  position: fixed;
  width: 226px;
  min-height: 100vh !important;
  border-right: 2px solid #ebebeb;
  background: #fff;
} */

.dashboard-sidebar .nav-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0 9px 33px 21px;
  color: #fff;
  font-size: 16px;
}
.svg-line {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
  margin-bottom: 91px;
}
.nav-link.active-link {
  background: rgba(255, 255, 255, 0.10);
  padding: 6px 20px 7px 12px;
  margin-right: 9px;
  border-radius: 8px;
}
.dashboard-sidebar .icon {
  margin-right: 8px;
}
.active-link svg {
  fill: #0e8080 !important;
}
.active-link .icon {
  fill: #0e8080;
}
.active-link .icon {
  filter: grayscale(0%) hue-rotate(156deg);
}

/* Integrations Tab */

.integration-cards-container {
  display: flex;
  justify-content: flex-start;
  /* align-items:flex-start; */
  gap: 28px;
  margin-bottom: 104px;
  padding: 20px;
}
.integration-card {
  display: inline-flex;
  height: 213px;
  width: 213px;
  padding: 20px 10px 5px 10px;
  flex-shrink: 0;
  border-radius: 8px;
  text-align: left;
  border: 1.012px solid #ebebeb;
}
.integration-card-title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.integration-card-subtitle {
  margin: 0;
  font-size: 16px;
}
.integration-card-desc {
color: #7b7b7b !important;
  font-size: 16px;
}
.integration-card-image {
  border-radius: 10px;
  margin-right: 10px;
}
.integration-card-button {
  display: flex;
  height: 29px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f6f6f6;
  color: #7b7b7b;
  align-items: center;
  border: 1px solid #f6f6f6;
}
.integration-main-container {
  /* width: 968px;
  height: 741px; */
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  margin-top: 5px;
  border-radius: 13.15px;
  border: 1.012px solid #ebebeb;
  background: #fff;
}
.container-label {
  display: flex;
  justify-content: flex-start;
  margin: 35px 0 49px 24px;
  font-size: 24px;
  color: #1d2739;
  font-weight: 600;
  line-height: normal;
}
.integration-list-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
}

.integration-list {
  /* width: 100%; */
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  cursor: pointer;
  margin-bottom: 24px;
}
.integration-list-title {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: #7b7b7b;
}

.integration-list-subtitle {
  margin: 0;
  display: flex;
  font-size: 16px;
  color: #7b7b7b;
}

.messages-list-subtitle{
  margin: 0;
  display: flex;
  font-size: 16px;
  color:#1D2739;
}

.integration-list-desc {
  color: #7b7b7b;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.integration-card-image {
  border-radius: 10px;
  margin-right: 10px;
}

.integration-list-button {
  display: flex;
  height: 29px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f6f6f6;
  color: #7b7b7b;
  align-items: center;
  border: 1px solid #f6f6f6;
  white-space: nowrap;
}
.integration-list-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.integration-list-info {
  display: flex;
  align-items: center;
}
.integration-tab-toggle-views {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
}
.integration-tab-view-icons {
  display: flex;
  margin-right: 32px;
  gap: 6px;
  border-radius: 8px;
  background: #D9D9D9;
  margin-right: 40px;
  padding: 6px 12px;
}
.integration-tab-view-icons .active {
  border-radius: 4.941px;
  background: #FFF;
  padding: 3px 4px;
  }

/* Pagination Bar */

.pagination-bar {
  position: absolute;
  width: calc(100% - 226px);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 20px;
  margin-top: 40px;
  padding: 10px;
  box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.25);
  background: #fff;
  /* bottom: 0; */
}
.pagination-arrow {
  display: flex;
  margin: 0 20px;
}

/* Marketplace Tab/ Suppliers List */

.filter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 32px;
  padding-bottom: 26px;
  gap: 10px;
}
.supplier-search-input {
  display: flex;
  width: 373px;
  height: 42px;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  background: #f6f6f6;
  padding-left: 40px;
}
.filter-search-button {
  display: inline-flex;
  height: 42px;
  width: 100px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 8px;
  background: #0E8080;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 20px;
}
.toggle-views {
  display: inline-flex;
  height: 42px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #F6F6F6;
  margin-right: 40px;

}
.view-icons {
  display: flex;
  gap: 6px;
}
.view-icons .active {
border-radius: 4.941px;
background: #FFF;
padding: 3px 4px;
}
.talents-list {
  /* width: 100%; */
  padding: 20px 31px 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  margin-bottom: 24px;
  border: 1px solid #f5f5f5;
}

.supplier-rating {
  display: flex;
  background-color: #f5f5f5;
  border-radius: 5px;
  padding: 0 10px;
  margin-left: 10px;
}
.about-talent {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.talent-certificates {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.list-of-cert {
  display: flex;
  flex-direction: row;
  gap: 13px;
}
.list-of-cert span {
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 38.619px;
  background: #f5f5f5;
  color: #545454;
}
.list-of-skills-pers-profile{
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  gap: 13px;
}
.list-of-skills-pers-profile h3{
  color: #000;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}
.list-of-skills-pers-profile span {
  display: inline-flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 38.619px;
  background: #f5f5f5;
  color: #545454;
  margin-right: 10px;
  margin-bottom: 10px;
}
.skills-row{
  margin-bottom: 20px;
 
}

.list-of-cert > .certificate {
  flex-basis: calc(20% - 13px); /* Adjust width for 5 certificates in a row */
}

@media (max-width: 800px) {
  .list-of-cert > .certificate {
    flex-basis: calc(25% - 13px); /* Adjust width for 4 certificates in a row on smaller screens */
  }
}
.talents-main-container {
  /* max-width: 1047px; */
  margin: 0 209px 32px 30px;
  /* margin-left: 30px; */
  /* margin-right: 10px; */
  border-radius: 14px;
  /* margin-bottom: 32px; */
  position: relative;
  min-height: 100vh;
}
.gallery-supplier-card {
  border-radius: 14.163px;
  border: 1.191px solid #f5f5f5;
  margin: 10px;
}
.gallery-card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 392px;
  max-width: 338px;
  margin-bottom: 20px;
  overflow: hidden;
}
.gallery-suppliers-main-container {
  position: relative;
  min-height: 80vh;
  margin-right: 50px;
}
.gallery-suppliers-list {
  display: flex;
}

/* Custom Dropdown Marketplace */
.marketplace-dropdown-container {
  display: flex;
  position: relative;
  height: 42px;
  padding: 4px 8px;
  align-items: center;
  background: #f6f6f6;
  border-radius: 8px;
  color: #7b7b7b;
}
.marketplace-custom-dropdown {
  display: inline-flex;
  height: 42px;
  align-items: center;
  padding: 0 10px;
  background: #f6f6f6;
}
.marketplace-dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: 0 0 4px 4px;
  display: none;
  position: absolute;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 1;
  left: 50%;
  transform: translateX(-50%);
}

.marketplace-dropdown-list.show {
  display: block;
  width: 210px;
  top: 110%;
  padding: 10px 10px 0 26px;
  max-height: 50vh;
  overflow-y: auto;
}
.marketplace-dropdown-list::-webkit-scrollbar {
  width: 0;
}
.marketplace-dropdown-item {
  display: flex;
  color: #4a4a4a;
  font-size: 16px;
  line-height: normal;
  cursor: pointer;
  padding-bottom: 20px;
}

/* Collaborations/Messages Tab */

.users-list {
  /* width: 100%; */
  cursor: pointer;
  padding: 0 12px 32px 11px;
}

.status-dot {
  width: 9px;
  height: 9px;
  background-color: #39dc00;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  margin-left: 35px;
}
.talent-overview {
  display: flex;
  flex-direction: column;
}
.messages-main-container {
  width: 313px;
  border-radius: 13.15px;
  border: 1px solid #f5f5f5;
  background: #fff;
  margin-left: 9px;
  margin-top: 9px;
  overflow: auto;
  margin-bottom: 10px;
  height: 701px;
  /* max-height: 701px; */
  /* min-height: 85vh; */
  /* height: auto; */
}
.messages-main-container::-webkit-scrollbar {
  width: 0;
}

.messages-container {
  display: flex;
  flex-direction: row;
}

.unread-messages {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 26px;
  background: #f00;
  margin-top: 15px;
  color: #fff;
  width: 8px;
  height: 15px;
}
.user-details-main-container {
  background: #fff;
  position: relative;
  margin-left: 10px;
  border-radius: 13px;
  margin-top: 9px;
  margin-right: 15px;
  margin-bottom: 10px;
  height: 701px;
  /* min-height: 85vh; */
  /* height: auto; */
  width: calc(100vw - 561px);
}

.user-details-container::-webkit-scrollbar {
  width: 0;
}
.user-details-header {
  padding: 34px 31px 21px 16px;
  border-bottom: 1px solid #ddd;
}
.supplier-profile-title {
  color: #1d2739;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
  margin: 0;
}
.supplier-profile-subtitle {
  color: #7b7b7b;
  font-size: 12px;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin: 0;
}

.supplier-profile-title-active {
  color: #0e8080;
  font-weight: 400;
  font-size: 14px;
  border-radius: 4.7px;
  background: rgba(14, 128, 128, 0.04);
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  margin: 0 0 0 30px;
}
.hire-btn {
  height: 36px;
  padding: 0 10px;

  border-radius: 8px;
  background: #9a9a9a;
  border: none;
  color: #fff;
  align-items: center;
  text-align: center;
}
.user-details-container {
  overflow: auto;
  max-height: 560px;
}

.user-message {
  border-radius: 15px;
  border: none;
  background: var(--purple);
  width: 371px;
  /* height: 85px; */
  display: flex;
  flex-direction: column;
  /* justify-content: flex-start; */
  /* padding: 14px 79px 42px 26px; */
  padding: 20px;
  color: #fff;
  /* align-items: flex-start; */
}
.receiver-message {
  border-radius: 15px;
  border: 1px solid #f9f9f9;
  background: #f9f9f9;
  width: 407px;
  /* height: 85px; */
  display: flex;
  flex-direction: column;
  padding: 20px;
  /* justify-content: flex-start; */
  /* padding: 14px 79px 42px 26px; */
  color: #6b6b6b;
  margin-bottom: 40px;
  margin-left: 10px;
  /* align-items: flex-start; */
}

/* Add these styles to your CSS file (Messages.css) */

.chat-search-input {
  background: #f4f4f4;
  outline: none;
  font-size: 16px;
  height: 48px;
  border: none;
  border-radius: 0 59px 59px 0;
  resize: none;
  padding: 10px;
  overflow: hidden;
  padding: 10px 20px;
  width: 100%;
  /* min-width: 40vw; */
  /* max-width: 885px; */
  margin-left: 55px;
  margin-right: 20px;
  margin-bottom: 30px;
}
.search-user-input {
  display: flex;
  width: 285px;
  height: 37px;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  border-radius: 8px;
  background: #f6f6f6;
  padding-left: 40px;
}

.send-message-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  bottom: 0;
  width: 95%;
  /* max-width: 885px; */
  margin-top: 20px;
}

.send-message-button {
  height: 48px;
  width: 48px;
  border: none;
  border-radius: 0 59px 59px 0;
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  margin-bottom: 30px;
  margin-right: 10px;
}
.collaborations-container-main {
  display: flex;
  border-bottom: 1px solid #ebebeb;
  height: 137px;
  justify-content: space-between;
  /* margin-left: 17px; */
  padding-top: 27px;
  background: #fff;
}
.collaborations-container-label {
  display: flex;
  font-size: 24px;
  color: #fff;
  font-weight: 600;
  line-height: normal;
}

.supplier-list-container {
  max-height: 680px;
  overflow: auto;
}
.supplier-list-container::-webkit-scrollbar {
  width: 0;
}

/* Management Tab */

.custom-edit-profile-input {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: none;
  background:  #F4F4F4;
  color: #7B7B7B;;
  height: 48px;
  outline: none;
}
.custom-edit-profile-input::placeholder{
  color: #7B7B7B;
  font-family: Lato;
font-size: 16px;
font-style: normal;
font-weight: 400;
}

.custom-edit-profile-button {
  width: 432px;
  height: 64px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: none;
  border-radius: 8px;
  background: linear-gradient(270deg, #CC9866 -12.83%, #722592 102.64%);    
  color: #ffffff;
  cursor: pointer;
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  margin-bottom: 20px;
}
.edit-profile-input-title{
  display: flex;
  color: #1D2739;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.custom-dropdown-edit-profile{
    display: flex;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M7 10L12 15L17 10' stroke='%231D2739' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
    /* background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M13 5.5L8 10.5L3 5.5' stroke='white' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E"); */
    background-repeat: no-repeat;
    background-position: right 10px center;
}

.custom-input-permission-edit-profile{
  width: 20%;
  padding: 10px;
  border-radius: 0 10px 10px 0;
  border: none;
  background: #F4F4F4;
  color: #7B7B7B;
  height: 48px;
  outline: none;
}

.send-invite-btn-edit-profile{
  display: inline-flex;
height: 36px;
padding: 10px;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 8px;
background: #0E8080;
color:#fff;
border: none;
outline: none;
}
.donut-chart {
  display: flex;
  width: 100%;
  height: 437px;
  border-radius: 15.341px;
  border: 0.959px solid #ebebeb;
  background: #fff;
  margin: 10px;
}

.bar-chart {
  margin: 10px;
  border-radius: 15.341px;
  border: 0.959px solid #ebebeb;
  background: #fff;
}
.chart-position {
  flex-grow: 1;
  margin-right: 50px;
  width: 297px;
  height: 297px;
}
.diversity-pie-chart-position{
  /* flex-grow: 1; */
  width: 205px;
  height: 205px;
}
.supplier-number-box {
  display: flex;
  width: 221px;
  height: 97px;
  padding: 9px 0px 12px 11px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: 10px;
  border-radius: 8px;
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  background: #FFF;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.10);
}
.done-btn{
  margin-top: 100px;
  display: flex;
width: 76px;
height: 36px;
padding: 5.696px 11.392px;
justify-content: center;
align-items: center;
gap: 7.595px;
flex-shrink: 0;
border-radius: 8px;
background: #0E8080;
color: #fff;
border: none;

}
.create-supplier-btn {
  display: inline-flex;
  height: 47px;
  padding: 6px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  opacity: 0.9;
  background: var(--purple);
  color: #fff;
  border: none;
  margin-right: 66px;
  margin-top: 13px;
  padding-left: 40px;
}
.suppliers-table {
  border-radius: 13.15px;
  background: #fff;
}
.suppliers-table tbody tr {
  border-bottom: 1.012px solid #f6f6f6;
}
.suppliers-table {
  width: 100%;

  min-height: 550px;
  /* width: 90%; */
  overflow: auto;
  /* text-align: left; */
}

.table-header {
  display: flex;
  border-bottom: 1px solid #f6f6f6;
  background: #fbfbfb;
  padding: 15px;
  font-size: 15px;
}

.table-row {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f6f6f6;
  font-size: 13.5px;
  color: #1d2739;
}

.column {
  flex: 1;
  /* flex-shrink: 0; */
  /* white-space: nowrap; */
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
}

.column:nth-child(8) {
  flex: 0.5;
}

.column:first-child {
  flex: 1.5;
  text-align: left;
  word-break: break-word;
  /* white-space: nowrap; */
}
.column:nth-child(2) {
  flex: 0.6;
}
/* ResponsiveTable.css */
/* .responsive-table {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1em;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}

th, td {
  padding: 0.5em;
  border-bottom: 1px solid #ddd;
  text-align: left;
  margin-right: 10px;

} */

.responsive-table {
  overflow-x: auto;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1em;
  /* table-layout: fixed; */
}

th,
td {
  padding: 10px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  /* overflow: hidden;  */

  /* text-overflow: ellipsis; */
}
td:first-child {
  white-space: nowrap;
  overflow-wrap: break-word;
  text-align: left;
  /* margin-right: 20px;  */
  /* width: %; */
}
th,
td:nth-child(8) {
  width: 5%;
}
th {
  font-size: 16px;
  font-weight: 400;
  color: #1d2739;
  /* white-space: nowrap; */
  /* text-align: left; */
}
td {
  font-size: 14px;
  font-weight: 400;
  color: #1d2739;
}

.filter-btn {
  display: flex;
  padding: 10px;
  gap: 10px;
  margin-top: 21px;
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #fbfbfb;
  color: #7b7b7b;
  font-size: 16px;
  font-weight: 500;
  background: #fbfbfb;
  padding: 10px;
  padding-right: 30px;
  margin-left: 5px;
  margin-right: 20px;
}
.selected-filters {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  /* border-radius: 4px; */
  /* background: #FBFBFB; */
}
.selected-filter {
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #fbfbfb;
  padding: 10px;
}
.filter-item {
  display: inline-flex;
  /* padding: 10px; */
  justify-content: center;
  align-items: center;
  color: #0e8080;
  margin-right: 10px;
}
.x-icon {
  width: 16px;
  color: #0e8080;
  cursor: pointer;
}

.dots-menu {
  display: flex;
  flex-direction: column;
  width: 184px;
  height: 163px;
  border-radius: 8px;
  border: 1.041px solid #ebebeb;
  background: #fff;
  box-shadow: 0px 8.326px 23.728px 0px rgba(0, 0, 0, 0.14);
  position: absolute;
  right: 50px;
  z-index: 999;
  align-items: flex-start;
  gap: 34.344px;
  color: #7b7b7b;
  font-size: 16px;
  padding: 10px;
}

.new-supplier-container {
  width: 658px;
  height: 768px;
  border-radius: 8px;
  background: #fff;
}
.supplier-created-container {
  display: flex;
  flex-direction: column;
  width: 658px;
  height: 432px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 20%;
  transform: translateX(-50%);
  z-index: 999;
}

.logout-btn{
display: flex;
padding: 12px 58px 11px 30px;
align-items: center;
border-radius: 7px;
border: 1px solid rgba(221, 221, 221, 0.87);
background: #FFF;
box-shadow: 0px 4px 91.2px -4px rgba(0, 0, 0, 0.10);
}

/* Overview Tab */

.overview-edit-btn{
  align-self: flex-end;
  display: inline-flex;
height: 36px;
width: 76px;
padding: 10px 30px 10px 10px ;
flex-direction: column;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border: none;
border-radius: 8px;
border: 1px solid #EBEBEB;
background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 50%, rgba(14, 128, 128, 0.03) 100%);
color: #1D2739;
background-color: transparent;
}

.overview-edit-btn:hover {
  background-color: var(--purple);
  color: #fff ;

}

.engagement-metrics-container{
display: inline-flex;
padding: 10px 10px 20px 10px;
flex-direction: column;
align-items: flex-start;
gap: 8.115px;
border-radius: 11.594px;
border: 0.725px solid #EBEBEB;
background: #FFF;
height: 630px;
margin:10px
}
.overview-budget-cards{
  width: 399px;
  height: 184px;
  border-radius: 16px;
  padding: 20px;
  margin: 10px;
  border-radius: 16px;
  border: 1px solid #EBEBEB;
}
.budget-card{

  background: linear-gradient(0deg, rgba(255, 255, 255, 0.00) 50%, rgba(14, 128, 128, 0.03) 100%);
}
.monthly-spends-card{
  background: linear-gradient(0deg, rgba(204, 152, 102, 0.00) 4.35%, rgba(121, 45, 142, 0.08) 100%);
}
.line-chart-container{
  border-radius: 15.341px;
   border:1px solid #EBEBEB;
    margin-left:10px;
    margin-top:10px;
    width: 820px;
    height: 425px;
}
.edit-budget-container{
  width: 658px;
  height: 730px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  position: absolute;
  left: 50%;
  top: 5%;
  transform: translateX(-50%);
  z-index: 999;
padding: 75px 43px 20px 43px;

}

.request-quote-container{
  width: 658px;
  height: 730px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background: #fff;
  position: absolute;
  left: 30%;
  top: -80px;
  transform: translateX(-50%);
  z-index: 999;
padding: 75px 43px 20px 43px;

}
.review-btn{
display: flex;
width: 76.897px;
height: 31.328px;
padding: 5.696px 11.392px;
justify-content: center;
align-items: center;
gap: 7.595px;
border-radius: 3.797px;
border: 0.949px solid #CDCDCD;
opacity: 0.9;
font-size: 15px;
font-weight: 600;
background-color: #fff;
color: #fff;

}
.row-even {
  border-radius: 4px 4px 0px 0px;
background: #F5F5F5;
}
.mapped-btn{
  display: flex;
  width: 233px;
  height: 48px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
background: rgba(14, 128, 128, 0.05);
color:#0E8080;
border: none;
}
.done-btn-mapping-table{
  display: flex;
width: 114px;
height: 50px;
max-height: 64px;
padding: 22px 38px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 8px;
background: #0E8080;
color:#fff;
border: none;
}
.unmapped-btn{
  display: flex;
width: 283px;
height: 48px;
padding: 10px;
justify-content: center;
align-items: center;
gap: 10px;
flex-shrink: 0;
border-radius: 8px;
background: rgba(255, 138, 0, 0.10);
border: none;
color: #FF7A00;
}

.pending{
display: inline-flex;
height: 34px;
padding: 10px;
justify-content: center;
align-items: center;
color: #FF5C00;
border-radius: 6px;
background: rgba(255, 168, 0, 0.08);
font-size: 13.488px;
font-weight: 400;
}

@media screen and (max-width: 1440px) {
  .chart-position {
    margin-right: 10px;
  }
  .chart-labels {
    font-size: 12px;
  }
  .suppliers-table {
    width: 100%;
    height: 665px;
  }
  .overview-budget-cards{
    width: 320px;
  }
  .line-chart-container{
      width:665px;
      height: 422px;
  }
}

/* Personal Profile*/

.edit-supplier-info-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 58px 38px;
}
.skills-container{
  border-radius: 16px;
border: 1px solid #EBEBEB;
padding: 11px 17px 56px 16px;
width: 100%;
}

.skills-span{
  margin-bottom: 20px;
  margin-top: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.settings-general{
  background: #fff;
}

/* Media query for tablets and medium-sized screens */
@media screen and (max-width: 1024px) {
  .chart-position {
    margin-right: 10px;
  }
  .chart-labels {
    font-size: 14px;
  }
  .suppliers-table {
    width: 100%;
    min-height: 500px;
  }
}
