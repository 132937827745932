body {
  font-family: 'Lato', sans-serif;
}
.signup-main-container {
  display: flex;
  padding: 30px 0 30px 0;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  z-index: 1000;
  background: var(--Gradient-vert);

  
}
.signup-container { 
  background: rgba(255, 255, 255, 0.30); 
  border-radius: 10px;
  width: 658px; 
  height: 767px;
  
}
.container-title{
  color: #FFF;
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
margin-bottom: 16px;
margin-top: 0;
}

.container-subtitle{
  color: #FFF;
  font-family: Lato;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 144.523%;
  margin-bottom: 91px;
  }

  .cards-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap:24px;
    margin-bottom: 104px;
  }
  .card-container{
  display: inline-flex;
  height: 208px;
  width: 204px;
  padding: 24px 13px;
  align-items: flex-start;
  /* gap: 24px; */
  flex-shrink: 0;
  border-radius: 8px;
  background: rgba(7, 7, 7, 0.15);
  text-align: flex-start;
  }
  .signup-card-subtitle{
    display: flex;
    color: #fff;
    font-family: Lato;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.4px;
    margin-top: 24px;
    margin-bottom: 9px;
    }
    .select-card-text{
      display: flex;
      text-align: left;
      color: #fff;
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 178px;
    margin: 0;
    }
    .custom-button-select-account{
    width: 432px;
    height: 64px;
    padding: 22px 0;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-shrink: 0;
    border: none;
    background: rgba(0, 0, 0, 0.30);
    color: #FFF;
    cursor: pointer;
    border-radius: 8px;
    margin-bottom: 85px;
    }

.selected{
background: var(--purple);
color: #fff;
}
.selected .signup-card-subtitle,
.selected .select-card-text,
.selected .card-image {
  filter: brightness(0) invert(1);
}

.selected .custom-button-select-account{
  background: var(--purple);
  color: #fff;
}

@media screen and (max-width: 768px) {
  .signup-container{
    width: 100vw; 
    height: 100vh;
    max-width: none; 
    overflow-y: scroll;
    padding: 20px;
}

  .cards-container {
    flex-direction: column;
    gap: 16px;
  }

  .card-container {
    width: 100%;
  }

  .custom-button-select-account {
    width: 100%;
  }
}