/* PreviewPopup.css */
.preview-popup {
  /* position: absolute; */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Set a higher z-index to appear on top */
}

.preview-content {
display: flex;
flex-direction: column;
  background: #fff;
  padding: 20px;
  width: 521px;
  height: 636px;
  overflow-y: auto;
  /* border-radius: 8px; */
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); */
  /* justify-content: flex-end; */
}
.preview-content::-webkit-scrollbar {
  width: 0;
}
.close-preview-btn {
  display: flex;
  width: 76.897px;
  height: 31.328px;
  padding: 5.696px 11.392px;
  justify-content: center; 
  align-items: center;
  margin-top: 40px;
  background-color: #0E8080;
  color: #ffffff;
  border-radius: 3.797px;
  border: 1px solid #0E8080;
}


