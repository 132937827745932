.progress-bar-container {
  margin: 0 61px 44px 61px;
}

.progress-steps {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  color:#fff;
}

.progress-step {
  height: 20px;
  gap: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap; 
  margin: 0 10px; 
}
.progress-step::before {
  content: "";
  display: flex;
  width: 55px;
  height: 3px;
  align-items: center;
  border-radius: 18px;
background: #000000;
}
.progress-step.active::before {
  background: #fff; 
}
.progress-step:first-child::before {
  display: none;
}
.progress-step.active {
  color: #fff
}
.progress-step.selected {
  color: #fff /* Change color for the selected step */
}